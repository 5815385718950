import { useMemo } from 'react'
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context';
import type { GetServerSidePropsContext } from 'next';
import type { IncomingMessage } from 'http';
import isEqual from 'lodash/isEqual';
import type { NormalizedCacheObject } from '@apollo/client';
import merge from 'deepmerge';
import cookie from 'cookie';


export const APOLLO_STATE_PROPERTY_NAME = '__APOLLO_STATE__';
export const COOKIES_TOKEN_NAME = process.env.NEXT_PUBLIC_BASE_URL!.startsWith('https') ? '__Secure-next-auth.session-token' : 'next-auth.session-token';

let apolloClient: ApolloClient<NormalizedCacheObject>;

const getToken = (req?: IncomingMessage) => {
  const parsedCookie = cookie.parse(
    req ? req.headers.cookie ?? '' : document.cookie,
  );

  return parsedCookie[COOKIES_TOKEN_NAME];
};

// function createIsomorphLink() {
//   // if (typeof window === 'undefined') {
//   //   const { SchemaLink } = require('@apollo/client/link/schema')
//   //   const { schema } = require('./schema')
//   //   return new SchemaLink({ schema })
//   // } else {
//     const { HttpLink } = require('@apollo/client/link/http')
//     return new HttpLink({
//       uri: '/api/graphql',
//       credentials: 'same-origin',
//     })
//   // }
// }

function createApolloClient(context?: GetServerSidePropsContext) {
  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
    credentials: 'same-origin',
  });

  const authLink = setContext((_, { headers }) => {
    // Get the authentication token from cookies
    const token = getToken(context?.req);

    return {
      headers: {
        ...headers,
        // authorization: token ? `Bearer ${token}` : undefined,
        Cookie: token ? `${COOKIES_TOKEN_NAME}=${token}` : undefined,
      },
    };
  });

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })
}

export function initializeApollo(initialState = null, context?: GetServerSidePropsContext) {
  const _apolloClient = apolloClient ?? createApolloClient(context)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function useApollo(initialState) {
  const store = useMemo(() => initializeApollo(initialState), [initialState])
  return store
}
