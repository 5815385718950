// import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';  //theme
import 'primereact/resources/primereact.min.css';                  //core css
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import "@clientio/rappid/rappid.css";
import '../scss/layout/layout.scss';
import '../styles/globals.scss';
import '../icomoon/style.scss';
import PrimeReact from 'primereact/api';
import type { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '../apollo/client';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from "dayjs";
import { RecoilRoot } from 'recoil';
dayjs.extend(relativeTime)

// temporary fix expired rappid licence
if (typeof window !== "undefined") {
  const a = window.alert;
  window.alert = (...params) => {
    // console.log(...params);
    // window.alert = a;
  }
}


PrimeReact.ripple = true;

function MyApp({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps.initialApolloState)

  return (
    <SessionProvider session={pageProps.session}>
      <ApolloProvider client={apolloClient}>
        <RecoilRoot>
          <Component {...pageProps} />
        </RecoilRoot>
      </ApolloProvider>
    </SessionProvider>
  )
}

export default MyApp
